import React, { createContext, useState, useEffect, useRef } from 'react';
import api from './api';
import KeycloakService from './keycloak';

export const KeycloakContext = createContext();

export const KeycloakProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(null);
  const isInitialized = useRef(false);

  useEffect(() => {
    const initializeAndFetchProfile = async () => {
      if (!isInitialized.current) {
        isInitialized.current = true;
        try {
          await KeycloakService.initKeycloak();
          const token = KeycloakService.getToken();
          if (token) {
            api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const userDetails = await KeycloakService.getUserInfo();
            setUserInfo(userDetails);
          }
        } catch (err) {
          console.error('Error during Keycloak initialization:', err);
        }
      }
    };

    initializeAndFetchProfile();
  }, []);

  return (
    <KeycloakContext.Provider value={{ isInitialized: isInitialized.current, userInfo }}>
      {children}
    </KeycloakContext.Provider>
  );
};
