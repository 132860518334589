import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import styled from 'styled-components';
import { Button, Paper, Grid, Avatar, Menu, MenuItem, TextField } from '@mui/material';
//import Table from './Table.js';
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import SideDrawer from './Drawer.js';
import Table from './JsonCustometable.js';
import BarChart from './Charts.js';
import Popup from './Popup.js';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import getUserData from '../utils/index.js';

//Services
import commonServices from '../service';
import { getCurrentLevel, getTransactions } from '../service/services.js';
import { KeycloakContext } from '../service/KeycloakContext.js';

//images
import Logo from "../assets/images/Logo.png";
import Home from "../assets/images/Home.png";
import Chart from "../assets/images/Chart.png";
import Ellipse from "../assets/images/Ellipse.png";
import Icon from "../assets/images/Icon.png";
import En from "../assets/images/En.webp";

//icons
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import AvatarMenu from './Avatar.js';
import FundTransfer from './FundTransfer.js';
import Breadcrumb from './Breadcrumbs.js';
import TableContainer from './TableContainer.js';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import api from '../service/api.js';
import { Styledpaper } from './Home.js';


const drawerWidth = 240;
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ padding: '0px 12px',  minHeight: 'calc(100vh - 71px)'}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
export default function ClippedDrawer() {
    const [open, setOpen] = React.useState(true);
    const [value, setValue] = React.useState(0);
    const [showProfileMenu, setProfileMenu] = useState(null);
    const navigate = useNavigate();
    const [transaction, setTransactionData] = useState([])
    const [approverData, setApproverData] = useState({})
    const [detailedTransactions, setdetailedTransactions] = useState({})
    let user = getUserData();
    const { isInitialized, userInfo } = useContext(KeycloakContext)
    const { getQueryResult, postQueryResult } = commonServices();
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const statusKeys = {
        "IN_PROGRESS": "In Progress",
        "SUCCESS": "Success",
        "FAILED": "Failed",
        "PENDING_APPROVAL": "Pending Approval",
        "PENDING": "Pending",
        "APPROVED": "Approved",
        "REJECTED": "Rejected",
        "HIGH": "High",
        "LOW": "Low"
    }
    const [ispopup, setPopup] = React.useState(false);
    const [popupData, setPopupData] = React.useState({});
    const handleOpen = (tableData, idx) => {
        //let data = {amount:{transactionData.amount} beneficiaryName={selectedBeneficiaryData.beneficiaryName} transactionRefNumber={selectedAccountData.transactionRefNumber}}
        console.log(tableData, idx);
        setdetailedTransactions({ tableRowIdx: idx, data: tableData, currentTransaction: tableData[idx] });
        setValue(2)

    };
    const handleClose = () => {
        setPopup(false);
    };

    useEffect(() => {
        if(detailedTransactions.currentTransaction){
            const currentTrans = detailedTransactions.currentTransaction
            const userLevel = userInfo && getCurrentLevel(userInfo);
            const isCurrentUser = currentTrans.currentApprovalLevel == userLevel;
            const isDisable = currentTrans.status === 'PENDING_APPROVAL' || (isCurrentUser &&  currentTrans.status === 'FAILED');
            setIsBtnDisabled(isDisable);
        }
    },[detailedTransactions?.currentTransaction])

    const updateTransactions = async (status) => {
        const params = [{
            status,
            remarks: detailedTransactions?.currentTransaction?.remarks,
            transactionRefNumber: detailedTransactions?.currentTransaction?.transactionRefNumber,
        }]
        try{
            const response = await api.put('/transactions/bulk-approve',params);
            const updatedStatus = response.data[0].status;
            setdetailedTransactions(prevState => ({
                ...prevState,
                currentTransaction: {
                  ...prevState.currentTransaction,
                  status: updatedStatus,
                }
              }));
        }catch(err){
            console.log(err);
        }
    }
    const checkApproverStatus = (approverData, idx) => {
        if
            (
            approverData?.[idx - 1]?.status == "APPROVED" && approverData?.[idx]
        ) {
            return (true)
        }
    }

    function handleNextPrevious(type) {
        let idx = Number(detailedTransactions?.tableRowIdx);
        if (type == 'Next') {
            idx = idx + 1
        } else {
            idx = idx - 1
        }

        if (idx >= 0 && idx < detailedTransactions?.data?.length) {
            setdetailedTransactions({
                ...detailedTransactions,
                currentTransaction: detailedTransactions.data[idx],
                tableRowIdx: idx
            })
        }
    }
    const headers = { "transactionRefNumber": "Ref Number", "creaktedAt": "Date", "debitAccount": "Debit Account", "currency": "Currency", "amount": "Amount", "status": "Status" }
    return (
        <div>
            <Grid container xs={12} alignItems={'center'} justifyContent={'center'} alignContent={'center'}>
                <Grid item xs={9.5} sx={{ color: 'black' }}>
                    <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}><img src={open ? Logo : Icon} /> <Typography variant='inherit'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; Hey {userInfo?.name}! Good Morning</Typography></div>
                </Grid>
                <Grid item xs={0.5}>
                    <ViewQuiltRoundedIcon sx={{ color: 'white' }} />
                </Grid>
                <Grid item xs={0.5}>
                    <NotificationsIcon sx={{ color: 'white' }} />
                </Grid>
                <Grid item xs={0.5}>
                    <img src={En} width={'40%'}></img>
                </Grid>
                <Grid item xs={1}>
                    <AvatarMenu />
                </Grid>
            </Grid>

            <Box sx={{ display: 'flex', minHeight: 'calc(100vh - 71px)', height: '100%' }}>
                <BGImage src={Home} />
                <CssBaseline />

                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <Grid item >
                        <SideDrawer handleTab={handleChange} selectedTab={value} />
                    </Grid>
                    <Grid item sx={{width: '100%'}}>
                {/* <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Typography variant="h6" noWrap component="div">
                        Vietcombank
                    </Typography>
                </Toolbar>
            </AppBar> */}
                {/* <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', opacity: '0.8', borderRadius: '10px', margin: '20px', height: '93%' },
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignContent: 'stretch' }}>
                    <div>
                        <center> <img src={Logo} /></center>
                    </div>
                    <div>
                        <StyledButton variant="contained" btnColor="#431E69" margin="10px 0px">
                            <HomeOutlinedIcon /> &nbsp;Home
                        </StyledButton>
                    </div>
                    <Box sx={{ overflow: 'auto' }}>
                        <List>
                            <ListItem key={"text"} disablePadding>
                                <ListItemButton onClick={handleClick}>
                                    <ListItemIcon>
                                        <PaymentsOutlinedIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Payments" />
                                    {open ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={open} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItemButton sx={{ pl: 4 }}>
                                            <ListItemText primary="Starred" />
                                        </ListItemButton>
                                    </List>
                                </Collapse>
                            </ListItem>


                        </List>
                        <br /><br /><br />
                        <Divider />
                        <List>
                            {['Help', 'Settings'].map((text, index) => (
                                <ListItem key={text} disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                        </ListItemIcon>
                                        <ListItemText primary={text} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                            <br />
                            <Divider />
                            {['Language'].map((text, index) => (
                                <ListItem key={text} disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                        </ListItemIcon>
                                        <ListItemText primary={text} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </div>
            </Drawer> */}

                <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
                    <TabPanel value={value} index={0}>
                    <Grid container spacing={1} justifyContent={'space-between'} sx={{ height: '100%', minHeight: 'calc(100vh - 71px)' }}>
                            <Grid item xs={8}>
                                <Styledpaper sx={{ borderRadius: '4px' }}>
                                    <Typography variant='body2' sx={{ padding: '10px', fontWeight: 'bold' }}>Payment Requests</Typography>
                                    <BarChart />
                                </Styledpaper>
                            </Grid>
                            <Grid item xs={4}>
                                <div style={{ display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <Styledpaper sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', borderRadius: '4px', marginBottom: '5px', padding: '10px' }}>
                                        <Typography variant='subtitle2'>Review and approve high-value transactions with precision. Ensure all large transactions meet your compliance standards.</Typography>
                                        <center> <Button sx={{
                                            textTransform: 'none',
                                            backgroundColor: '#204E32', m1: 1, "&.MuiButtonBase-root:hover": {
                                                backgroundColor: '#204E32'
                                            }
                                        }} variant='contained'>High value transaction</Button></center>
                                    </Styledpaper>
                                    <Styledpaper sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', borderRadius: '4px', marginBottom: '0px', padding: '10px' }}>
                                        <Typography variant='subtitle2'>Access and manage transactions flagged as high priority. Stay on top of urgent tasks and maintain workflow efficiency.</Typography>
                                        {/* <Typography variant='subtitle2' sx={{ fontWeight: 'bold' }}>Quick, secure, and simple.</Typography> */}
                                        <center> <Button sx={{
                                            textTransform: 'none',
                                            backgroundColor: '#204E32', m1: 1, "&.MuiButtonBase-root:hover": {
                                                backgroundColor: '#204E32'
                                            }
                                        }} variant='contained'>Priority transaction queue</Button></center>
                                    </Styledpaper>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                {isInitialized && <TableContainer handlePop={handleOpen} userData={userInfo} />}
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Breadcrumb />
                        <FundTransfer />
                    </TabPanel>
                    <TabPanel value={value} index={2}>

                        <Styledpaper sx={{ bgcolor: 'white', height: '100%', minHeight: 'calc(100vh - 71px)' }}>
                            <Grid container direction={'row'} xs={12} alignItems={'center'} alignContent={'center'} sx={{ padding: '10px 0px 0px 15px' }}>
                                <Grid item xs={6}>
                                    <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>Ref number #{detailedTransactions?.currentTransaction?.transactionRefNumber}</Typography>
                                </Grid>
                                <Grid container item xs={6} alignItems={'center'} alignContent={'center'} justifyItems={'center'} justifyContent={'end'} >
                                    <Grid item xs={1}>
                                        <NavigateBeforeIcon onClick={() => handleNextPrevious('Previous')} />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <NavigateNextIcon onClick={() => handleNextPrevious('Next')} />
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                >
                                    <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>Transaction Details</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container xs={12} direction={'column'}>
                                        <Grid item xs={12}>
                                            <TextField id="standard-basic" label="CIF Name" value={detailedTransactions?.currentTransaction?.debitAccount?.accountName} variant="standard" InputProps={{ disableUnderline: true, shrink: true, sx: { fontSize: 16 } }} InputLabelProps={{ sx: { fontSize: 16, fontWeight: 'bold' } }} />
                                            <TextField id="standard-basic" label="CIF ID" value={detailedTransactions?.currentTransaction?.debitAccount?.cifNumber} variant="standard" InputProps={{ disableUnderline: true, shrink: true, sx: { fontSize: 16 } }} InputLabelProps={{ sx: { fontSize: 16, fontWeight: 'bold' } }} />
                                            <TextField id="standard-basic" label="Debit Account" value={detailedTransactions?.currentTransaction?.debitAccount?.accountNumber} variant="standard" InputProps={{ disableUnderline: true, shrink: true, sx: { fontSize: 16 } }} InputLabelProps={{ sx: { fontSize: 16, fontWeight: 'bold' } }} />
                                            <TextField id="standard-basic" label="Beneficiary Account" value={detailedTransactions?.currentTransaction?.beneficiaryAccount?.beneficiaryAccountNumber} variant="standard" InputProps={{ disableUnderline: true, shrink: true, sx: { fontSize: 16 } }} InputLabelProps={{ sx: { fontSize: 16, fontWeight: 'bold' } }} />
                                            <TextField id="standard-basic" label="Beneficiary Name" value={detailedTransactions?.currentTransaction?.beneficiaryAccount?.beneficiaryName} variant="standard" InputProps={{ disableUnderline: true, shrink: true, sx: { fontSize: 16 } }} InputLabelProps={{ sx: { fontSize: 16, fontWeight: 'bold' } }} />
                                        </Grid>
                                        <Grid item xs={12} sx={{ paddingTop: '20px' }}>
                                            {/* <TextField id="standard-basic" label="Transaction ref number" value={detailedTransactions?.currentTransaction?.transactionRefNumber} variant="standard" InputProps={{ disableUnderline: true, shrink: true, sx: { fontSize: 16 } }} InputLabelProps={{ sx: { fontSize: 16 } }} /> */}
                                            <TextField id="standard-basic" label="Amount" value={new Intl.NumberFormat('vi-VN').format(detailedTransactions?.currentTransaction?.amount)} variant="standard" InputProps={{ disableUnderline: true, shrink: true, sx: { fontSize: 16 } }} InputLabelProps={{ sx: { fontSize: 16, fontWeight: 'bold' } }} />
                                            <TextField id="standard-basic" label="Currency" variant="standard" value={detailedTransactions?.currentTransaction?.currency} InputProps={{ disableUnderline: true, shrink: true, sx: { fontSize: 16 } }} InputLabelProps={{ sx: { fontSize: 16, fontWeight: 'bold' } }} />
                                            <TextField id="standard-basic" label="Transaction type" variant="standard" value={detailedTransactions?.currentTransaction?.transactionType} InputProps={{ disableUnderline: true, shrink: true, sx: { fontSize: 16 } }} InputLabelProps={{ sx: { fontSize: 16, fontWeight: 'bold' } }} />
                                            <TextField id="standard-basic" label="Transaction Date" variant="standard" value={new Date(detailedTransactions?.currentTransaction?.createdAt).toLocaleDateString()} InputProps={{ disableUnderline: true, shrink: true, sx: { fontSize: 16 } }} InputLabelProps={{ sx: { fontSize: 16, fontWeight: 'bold' } }} />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion defaultExpanded>
                            <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                >
                                    <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>Additional Details</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container xs={12} direction={'column'}>
                                        <Grid item xs={10}>
                                            <TextField id="standard-basic" label="Maker ID" value={detailedTransactions?.currentTransaction?.debitAccount?.userNumber} variant="standard" InputProps={{ disableUnderline: true, shrink: true, sx: { fontSize: 16 } }} InputLabelProps={{ sx: { fontSize: 16, fontWeight: 'bold' } }} />
                                            <TextField id="standard-basic" label="Product Code" value={'LPAY'} variant="standard" InputProps={{ disableUnderline: true, shrink: true, sx: { fontSize: 16 } }} InputLabelProps={{ sx: { fontSize: 16, fontWeight: 'bold' } }} />
                                            <TextField id="standard-basic" label="Subproduct Code" variant="standard" value={'IFT'} InputProps={{ disableUnderline: true, shrink: true, sx: { fontSize: 16 } }} InputLabelProps={{ sx: { fontSize: 16, fontWeight: 'bold' } }} />
                                            <TextField id="standard-basic" label="Maker Remarks" variant="standard" value={detailedTransactions?.currentTransaction?.remarks} InputProps={{ disableUnderline: true, shrink: true, sx: { fontSize: 16 } }} InputLabelProps={{ sx: { fontSize: 16, fontWeight: 'bold' } }} />
                                            <TextField id="standard-basic" label="Priority" variant="standard" value={statusKeys[detailedTransactions?.currentTransaction?.priority]} InputProps={{ disableUnderline: true, shrink: true, sx: { fontSize: 16 } }} InputLabelProps={{ sx: { fontSize: 16, fontWeight: 'bold' } }} />

                                        </Grid>
                                        <Grid item xs={10} sx={{ paddingTop: '25px' }}>
                                            <TextField id="standard-basic" label={`Approver1 ${detailedTransactions?.currentTransaction?.transactionApprovers?.[0]?.userNumber || ''}`} value={statusKeys[detailedTransactions?.currentTransaction?.transactionApprovers?.[0]?.status]} variant="standard" InputProps={{ disableUnderline: true, shrink: true, sx: { fontSize: 16 } }} InputLabelProps={{ sx: { fontSize: 16, fontWeight: 'bold' } }} />
                                            {checkApproverStatus(detailedTransactions?.currentTransaction?.transactionApprovers, 1) &&
                                                <>
                                                    <TextField id="standard-basic" label={`Approver2 ${detailedTransactions?.currentTransaction?.transactionApprovers?.[1]?.userNumber || ''}`} value={statusKeys[detailedTransactions?.currentTransaction?.transactionApprovers?.[1]?.status]} variant="standard" InputProps={{ disableUnderline: true, shrink: true, sx: { fontSize: 16 } }} InputLabelProps={{ sx: { fontSize: 16, fontWeight: 'bold' } }} />
                                                    {checkApproverStatus(detailedTransactions?.currentTransaction?.transactionApprovers, 2) &&
                                                        <TextField id="standard-basic" label={`Approver3 ${detailedTransactions?.currentTransaction?.transactionApprovers?.[2]?.userNumber || ''}`} value={statusKeys[detailedTransactions?.currentTransaction?.transactionApprovers?.[2]?.status]} variant="standard" InputProps={{ disableUnderline: true, shrink: true, sx: { fontSize: 16 } }} InputLabelProps={{ sx: { fontSize: 16, fontWeight: 'bold' } }} />
                                                    }
                                                </>}
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                                <Grid container xs={12} sx={{ padding: '20px' }}>
                                    <Grid item xs={10}>
                                        <Button variant='outlined' color='inherit' size="medium" sx={{ textTransform: 'none' }} onClick={() => setValue(0)}>Back</Button>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Button variant='outlined'
                                            disabled={!isBtnDisabled} 
                                         color='error' size="medium" sx={{ textTransform: 'none' }} onClick={() => updateTransactions("REJECTED")}>Reject</Button>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Button variant='contained'
                                            disabled={!isBtnDisabled} 
                                        size="medium" sx={{
                                            textTransform: 'none',
                                            backgroundColor: '#204E32', m1: 1, "&.MuiButtonBase-root:hover": {
                                                backgroundColor: '#204E32'
                                            }
                                        }} onClick={() => updateTransactions("APPROVED")}>Approve</Button>
                                    </Grid>
                                </Grid>
                            </Accordion>

                        </Styledpaper>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        Item Four
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        Item Five
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        Item Six
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                        Item Seven
                    </TabPanel>
                
                    </Box>
                    </Grid>
                </div>
            </Box>
        </div >
    );
}
const BGImage = styled.img`
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
`
export const StyledButton = styled(Button)`
  a {
    color: #2941b7;
    text-decoration: none;
    color: ${(props) => (props.textColor ? props.textColor : props.color)};
  }
  &.MuiButton-root {
    text-decoration: none;
    text-align: left;
    width: 100%;
    margin: ${(props) => (props.margin ? props.margin : "5px")};
    background-color: ${(props) => (props.btnColor ? props.btnColor : props.color)};
    color: ${(props) => (props.txtColor ? props.txtColor : props.color)};
      color: ${(props) => (props.textColor ? props.textColor : props.color)};
    @media only screen and (max-width: 900px) {
      margin: 3px;
      padding: 8px 18px;
      font-size: 12px;
    }
    @media only screen and (max-width: 450px) {
      margin: 3px;
      padding: 6px 15px;
      font-size: 10px;
    }
  }
`;
const StyledMenu = styled(Menu)`
#menu{
 margin-top: 15px;
    overflow: hidden;
    top: 35px !important;
    right: 20px !important;
    width: 100px;
    position: absolute;
}
  .MuiPaper-root {
    margin-top: 15px;
    overflow: hidden;
    top: 35px;
    right: 20px;
    width: 100px;
    position: absolute;
  }
  a {
    text-decoration: none;
  }
  @media only screen and (max-width: 1200px) {
    .MuiPaper-root {
      overflow-y: scroll;
      margin-top: 50px;
    }
  }
  body {
    overflow: auto;
  }
`;
const ProfileContainer = styled.div``;
const ProfileMenuItem = styled(MenuItem)`
  font-size: 0.8rem;
  .svg-inline--fa {
    padding-right: 5px;
  }
`;
const NavbarContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
aling-content: center;
`
const NavRIghtItems = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
aling-content: center;
`