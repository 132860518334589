import React from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';

const Loader = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <div className="fixed inset-0 bg-white bg-opacity-70 flex justify-center items-center z-50">
          <PropagateLoader
            color="#204E32"
            loading={isLoading}
            cssOverride={{
              display: 'block',
              margin: '0 auto',
            }}
          />
        </div>
      )}
    </>
  );
};

export default Loader;
