import React, { useEffect, useContext } from 'react';
import Plot from 'react-plotly.js';
import Highcharts, { color } from 'highcharts';
import styled from 'styled-components';
import { KeycloakContext } from '../service/KeycloakContext.js';
import { getCurrentLevel } from '../service/services.js';
// function Charts() {
//     let trace1 = {
//         x: ['Salary', 'Vendor / Supplier', 'Supplier', 'Inter-company'],
//         y: [100, 20, 50, 16],
//         name: 'In Progress',
//         type: 'bar'
//     };

//     let trace2 = {
//         x: ['Salary', 'Vendor / Supplier', 'Supplier', 'Inter-company'],
//         y: [34, 56, 65, 29],
//         name: 'Failed',
//         type: 'bar'
//     };
//     let trace3 = {
//         x: ['Salary', 'Vendor / Supplier', 'Supplier', 'Inter-company'],
//         y: [64, 36, 58, 21],
//         name: 'Completed',
//         type: 'bar',
//     };

//     let data = [trace1, trace2, trace3];
//     const config = {
//         displayModeBar: false, // this is the line that hides the bar.
//         responsive: true
//       };
//     return (
//         <Plot
//             data={data}
//             layout={{ barmode: 'group', width: 600, height: 300}}
//             config={config}
//         />
//     )
// }
const sampleData = [
    [{
        name: 'Success',
        data: [130, 100, 80, 90],
        color: '#204E32 '
    }, {
        name: 'In Progress',
        data: [54, 56, 65, 29],
        color: '#E9933E'
    }, {
        name: 'Rejected',
        data: [35, 20, 18, 9],
        color: '#913D8F'
    }],
    [{
        name: 'Success',
        data: [50, 35, 45, 2],
        color: '#204E32'
    }, {
        name: 'In Progress',
        data: [20, 15, 12, 7],
        color: '#E9933E'
    }, {
        name: 'Pending Approval',
        data: [40, 32, 41, 22],
        color: '#431E69'
    }, {
        name: 'Rejected',
        data: [5, 7, 8, 2],
        color: '#913D8F'
    }],
    [{
        name: 'Success',
        data: [25, 17, 22, 11],
        color: '#204E32'
    }, {
        name: 'In Progress',
        data: [16, 12, 10, 11],
        color: '#E9933E'
    }, {
        name: 'Pending Approval',
        data: [25, 27, 31, 17],
        color: '#431E69'
    }, {
        name: 'Rejected',
        data: [3, 5, 4, 1],
        color: '#913D8F'
    }],
    [{
        name: 'Success',
        data: [25, 17, 22, 11],
        color: '#513438'
    }, {
        name: 'In Progress',
        data: [18, 13, 9, 5],
        color: '#E9933E'
    }, {
        name: 'Pending Approval',
        data: [12, 10, 5, 3],
        color: '#431E69'
    }, {
        name: 'Rejected',
        data: [1, 2, 1, 0],
        color: '#913D8F'
    }]
]

function Charts() {
    const formHighChart = (barchartdata, role) => {

        Highcharts.chart('container_1',
            {
                chart:
                {
                    type: 'column',
                },
                title:
                {
                    text: "",
                },
                subtitle:
                {
                },
                xAxis: {
                    categories: ['Salary', 'Supplier', 'Investment', 'Inter-company'],
                    title: {
                        text: null,
                    },
                },
                yAxis:
                {
                    min: 0,
                    title:
                    {
                        text: "Transactions",
                    },
                    labels:
                    {
                        overflow: 'justify',
                    },
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            format: '{point.y}'
                        }
                    }
                },
                tooltip:
                {
                    valueSuffix: '',
                    headerFormat: '',
                },
                credits: {
                    enabled: false
                },
                legend:
                {
                    align: 'center',
                    verticalAlign: 'bottom',
                    x: 0,
                    y: 0
                },
                series: barchartdata,
            });
    };
    const { userInfo } = useContext(KeycloakContext);
    useEffect(() => {
        if (userInfo) {
            const userLevel = getCurrentLevel(userInfo);
            const chartData = sampleData?.[userLevel];
            formHighChart(chartData)
        }
    }, [userInfo])

    return (
        <HighchartContainer id="container_1" display={true}></HighchartContainer>
    )
}
export default Charts

export const HighchartContainer = styled.div`
  display: ${(props) => (props.display ? "block" : "none")};
  height: 210px;
  border-radius: 5px;
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
`;