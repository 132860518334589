import { Api } from "@mui/icons-material";
import axios from "axios";
const API={
    "oip-recaptcha":"oip-recaptcha",
    "login":"login/api/v1/login",
    "getTransactions": "transaction/api/v1/transactions",
    "getAccounts": "transaction/api/v1/beneficiary/userNumber",
    "debitAccounts": "transaction/api/v1/account",
    "makeTransactions": "transaction/api/v1/transactions/initiate",
    "updateTransactions": "transaction/api/v1/transactions/bulk-approve"
}
const url='https://api.vietcombank.virtusa.dev/'
export function wrappedAxios(config) {
 
  return axios(config)
    .then((response) => {
      return response.data;     
    })
    .catch((err) => {
      if (err.response?.data?.status == 403) {
        throw new Error(err?.response?.status)
      } else {
        throw Error(err);
      }
    });
}

function commonServices() {
  // get request with query params
  const getQueryResult = (endpoint, params="", method) => {
    const complete_url = url+API[endpoint]+params;
    const config = {
      method: method || "GET",
      url: complete_url,
      headers: {
        Accept: "application/json",
      },
    };

    return wrappedAxios(config);
  };

  // post request with uploaded file as body
//   const uploadFile = (endpoint, file) => {
//     const solnUrl = solnEndpoints[endpoint];

//     const fd = new FormData();
//     fd.append("file", file);
//     const config = {
//       method: "POST",
//       url: `${domainURL}${solnUrl}`,
//       data: fd,
//       headers: {
//         Accept: "application/json",
//       },
//     };

//     return wrappedAxios(config);
//   };

//   // post request with multiple uploaded file as body
//   const multipleUploadFile = (endpoint, file) => {
//     const solnUrl = solnEndpoints[endpoint];
//     const fd = new FormData();
//     Object.entries(file)?.map((val) => {
//       fd.append("files", val[1]);
//     });
//     const config = {
//       method: "POST",
//       url: `${domainURL}${solnUrl}`,
//       data: fd,
//       headers: {
//         Accept: "application/json",
//       },
//     };
//     return wrappedAxios(config);
//   };

//   // post request with body
  const postQueryResult = (endpoint, params, method='POST') => {
    const complete_url = url+API[endpoint];
    const config = {
      method: method,
      url: complete_url,
      data: JSON.stringify(params),
      "async": true,
      "crossDomain": true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "cache-control": "no-cache",
      },
    };

    return wrappedAxios(config);
  };
//   const postFormDataQueryResult = (endpoint, params) => {
//     const solnUrl = solnEndpoints[endpoint];
//     const config = {
//       method: "POST",
//       url: `${domainURL}${solnUrl}`,
//       data: params,
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         "cache-control": "no-cache",
//       },
//     };

//     return wrappedAxios(config);
//   };

//   // post request with body for colbert
//   const postQueryResultColbert = (endpoint, params) => {
//     const solnUrl = solnEndpoints[endpoint];

//     const fd = new FormData();

//     Object.entries(params)?.map((val) => {
//       fd.append(`${val[0]}`, val[1]);
//     });
//     const config = {
//       method: "POST",
//       url: `${domainURL}${solnUrl}`,
//       data: fd,
//       "async": true,
//       "crossDomain": true,
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         "cache-control": "no-cache",
//       },
//     };

//     return wrappedAxios(config);
//   };

//   // multiple uploaded files and body params
//   const uploadFilesWithBody = (endpoint, files, params = "") => {
//     const solnUrl = solnEndpoints[endpoint] + params;
//     const fd = new FormData();

//     Object.entries(files)?.map((val) => {
//       fd.append(`${val[0]}`, val[1]);
//     });

//     const config = {
//       method: "POST",
//       url: `${domainURL}${solnUrl}`,
//       data: fd,
//       headers: {
//         Accept: "application/json",
//       },
//     };
//     return wrappedAxios(config);
//   };

  return {
    //uploadFile,
    //multipleUploadFile,
   // uploadFilesWithBody,
    getQueryResult,
    postQueryResult,
   // postQueryResultColbert,
   // postFormDataQueryResult
  };
}
export default commonServices;
