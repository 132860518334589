import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { Typography, Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const DemoPaper = styled(Paper)(({ theme }) => ({
    width: 400,
    height: 120,
    padding: theme.spacing(2),
    ...theme.typography.body2,
    textAlign: 'left',
    margin: '30px',
    padding: '20px'

  }));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #204E32',
  boxShadow: 20,
  pt: 2,
  px: 4,
  pb: 3,
};


export default function ChildModal(props) {
  

  return (
    <React.Fragment>
      <Modal
        open={true}
        onClose={props.handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 500, borderRadius: '4px' }}>
          <center>
          <CheckCircleIcon className="mb-5" style={{ fontSize: 80, color: '#308E55' }}/>
          <Typography variant='subtitle1'>Transaction Submitted Successfully!</Typography>
          <Typography variant='subtitle2'>
          The transaction you made was submitted successfully.
          </Typography>
          <DemoPaper square={false}>
            <Typography variant='body2'>Transaction reference number: {props.transactionRefNumber} </Typography>
            <Typography variant='body2'>Beneficiary account name: {props.beneficiaryName} </Typography>
            <Typography variant='body2'>Transaction amount: {new Intl.NumberFormat('vi-VN').format(props.amount)} {props.currency} </Typography>
          </DemoPaper>
          </center>
          <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant='contained' size="medium" sx={{
                    textTransform: 'none',
                    backgroundColor: '#204E32', m1: 1, "&.MuiButtonBase-root:hover": {
                        backgroundColor: '#204E32'
                    }
                }} onClick={props.handleClose}>Ok</Button>
          </Grid>
        </Box>
      </Modal>
    </React.Fragment>
  );
}


