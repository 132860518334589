import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from "react-router-dom";

//icons
import LogoutIcon from '@mui/icons-material/Logout';
import Ellipse from "../assets/images/Ellipse.png";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeycloakService from '../service/keycloak';

const AvatarMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        KeycloakService.doLogout();
    };
    return (
        <div>
            <IconButton onClick={handleClick}>
                <Avatar alt="User Avatar" src={Ellipse} /> <KeyboardArrowDownOutlinedIcon sx={{color: 'white'}}/>
            </IconButton>
            
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <MenuItem onClick={handleLogout}><LogoutIcon/> Logout</MenuItem>
            </Menu>
        </div>
    );
};
export default AvatarMenu;