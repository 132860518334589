import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import SignInSide from "./components/Login";
import Home from "./components/Home";
import Approver from "./components/Approver";
import { KeycloakContext } from "./service/KeycloakContext";
import Loader from "./components/Loader";

// let initOptions = {

//   url: 'https://sso.vietcombank.virtusa.dev/auth',
//   realm: 'vietcombank-realm',
//   clientId: 'vietcombank-fe'
// }

// let kc = new keycloak(initOptions);

// kc.init({
// onLoad: 'login-required',
// checkLoginIframe: true,
// pkcMethod: '5256',
// }).then((auth) => {
// if (!auth){
//   window.location.reload();
// }else{
//   console.info("Authenticated");
//   console.log('auth', auth)
//   console.log('Keyclock', kc)
//   console.log('Access Token', kc.token)

//   //httpClient.defaults.headers.common['Authorization'] = `Bearer ${kc.token}`;
//   kc.onTokenExpired = () => {
//     console.log ('token expired')
//   }
// }
// }, () => {
//   console.error ('Authentication Failed');
// });

export default function App() {

  const navigate = useNavigate();
  const isNavigated = useRef(false);
  const { isInitialized, userInfo } = useContext(KeycloakContext);

  useEffect(() => {
    if(!isNavigated.current && isInitialized && userInfo){
      isNavigated.current = true;
      userInfo.roles.includes('VCB_MAKER') ? navigate('/home') : navigate('/approver-dashboard')
    }
  }, [isInitialized]);
  if (!isInitialized) {
     return <Loader  isLoading={!isInitialized}/>
  }

  return (
    <Routes>
      <Route path="/login" element={<SignInSide />} />
      <Route path="/home" element={<Home />} />
      <Route path="/approver-dashboard" element={<Approver />} />
    </Routes>
  );
}
