import ls from 'localstorage-slim';
const lengthReg = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]{8,32}$/;
export const oneNumReg = /[0-9]/;
const onelowercaseReg = /[a-z]/;
const oneUppercaseReg = /[A-Z]/;
const oneSpecialCharReg = /[!@#\$%\^\&*\)\(+=._-]/;

export function passwordValidation(value) {
  return {
    pwdLength: lengthReg.test(value),
    oneLower: onelowercaseReg.test(value),
    oneUpper: oneUppercaseReg.test(value),
    oneNumber: oneNumReg.test(value),
    oneSpecialChar: oneSpecialCharReg.test(value),
  };
}
export function containsSpecialChars(str) {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialChars.test(str);
}
export function isPasswordValid(value) {
  return (
    lengthReg.test(value) &&
    onelowercaseReg.test(value) &&
    oneUppercaseReg.test(value) &&
    oneNumReg.test(value) &&
    oneSpecialCharReg.test(value)
  );
}

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
export function allowedDomains(email) {
  const notAllowedDomains = [
    "Mail.com",
    "aol.com",
    "gmail.com",
    "yahoo.com",
    "outlook.com",
    "Inbox.com",
    "iCloud.com",
    "mail.com",
    "Zoho.com",
    "protonmail.com",
  ];
  return notAllowedDomains.indexOf(email.split("@")[1]) == -1;
}
export function validatePhoneNumber(mobile) {
  const reg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return reg.test(mobile);
}
export const getUserData = () => ls.get('user', { decrypt: true });
export const roles = {
  MAKER: "MAKER",
  APPROVER1: "APPROVER1",
  APPROVER2: "APPROVER2",
  APPROVER3: "APPROVER3",
};
export const roleBasedRoutes = {
    MAKER: {
    initialRoute: "/home",
    allowedPages: ['/home'  ],
  },
  APPROVER1: {
    initialRoute: "/approver-dashboard",
    allowedPages: ['/approver-dashboard'],
  },
  APPROVER2: {
    initialRoute: "/approver-dashboard",
    allowedPages: ['/approver-dashboard'],
  },
  APPROVER3: {
    initialRoute: "/approver-dashboard",
    allowedPages: ['/approver-dashboard'],
  },
//   Reviewer: {
//     initialRoute: "/site-monitoring/reviewer-dashboard",
//     allowedPages: ['/site-monitoring/reviewer-dashboard', '/site-monitoring/reviewer-viewReport', '/site-monitoring/view-reports', '/site-monitoring/view-visit-report', '/site-monitoring/manage-visits', '/site-monitoring/reviewer-submision'],
//   },
//   SuperAdmin: {
//     initialRoute: "/site-monitoring/super-admin-dashboard",
//     allowedPages: ["/site-monitoring/super-admin-dashboard", "/site-monitoring/super-admin", '/site-monitoring/account-setting']
//   },
//   SiteUser: {
//     initialRoute: "/site-monitoring/siteuser-dashboard",
//     allowedPages: ["/site-monitoring/siteuser-dashboard", '/site-monitoring/reviewer-submision', '/site-monitoring/view-reports']
//   },
//   Auditor: {
//     initialRoute: "/site-monitoring/auditor-dashboard",
//     allowedPages: ["/site-monitoring/auditor-dashboard","/site-monitoring/audit-execution", "/site-monitoring/audit-exe-report"]
//   }
};
export const commonInfo = {
  roleBasedRoutes,
  roles
}
export default getUserData;