import React, { useRef } from 'react'
import { TextField, Paper, Grid, FormControl, Avatar, Menu, MenuItem, Typography, Divider, Button, Breadcrumbs, InputAdornment, } from '@mui/material';
import getUserData from '../utils/index.js';
import { useState, useEffect } from "react";
import Popup from "./Popup.js";


//Services
import commonServices from '../service';
import { getAccounts } from '../service/services.js';
import api from '../service/api.js';
import Loader from './Loader.js';
import { Styledpaper } from './Home.js';

function FundTransfer({ userData, selectedTransaction, handleBack }) {
    const [debitAccounts, setAccountData] = useState([])
    const [selectedAccountData, setselectedAccountData] = useState({})
    const [selectedAccount, setAccount] = useState('')
    const [beneficiary, setBeneficiary] = useState('')
    const [transactionData, setTransactionData] = useState({})
    const [selectedBeneficiary, setselectedBeneficiary] = useState('')
    const [selectedBeneficiaryData, setselectedBeneficiaryData] = useState({})
    const [ispopup, setPopup] = React.useState({});
    const [selectedPriority, setSelectedPriority] = useState('LOW');
    const [purpose, setPurpose] = useState('Others');
    const isAccountsFetched = useRef(false);
    const handleOpen = (popupData) => {
        setPopup({ isOpen: true, ...popupData });
    };

    const [isLoading,setIsLoading] = useState(false);

    const setFormValues = () => {
        if (selectedTransaction) {
            const { purpose, remarks, amount, beneficiaryAccount, debitAccount,
                taxCharges } = selectedTransaction
            setAccount(debitAccount.accountNumber);
            setselectedAccountData({ ...debitAccount, transactionCharge: taxCharges })
            setselectedBeneficiary(beneficiaryAccount.beneficiaryAccountNumber);
            setselectedBeneficiaryData(beneficiaryAccount);
            setPurpose(purpose);
            const selectedData = {
                remarks: remarks,
                total_amount: Number(taxCharges) + Number(amount),
                amount: amount
            }
            setTransactionData(selectedData)
        }
    }


    const handleClose = () => {
        setPopup({});
        handleBack();
    };
    let user = getUserData()
    const { getQueryResult, postQueryResult } = commonServices();

    const hadleMakeTransaction = async (e) => {
            setIsLoading(true);
            const params = {
                "debitAccount": selectedAccount,
                "beneficiaryAccountNumber": selectedBeneficiary,
                "currency": selectedAccountData.currency,
                "amount": transactionData.total_amount,
                "paymentType": selectedBeneficiaryData.paymentType,
                "remarks": transactionData.remarks || 'NA',
                "purpose": purpose || 'NA',
                "priority": selectedPriority
            }
            try {
                const res = await api.post('/transactions/initiate', params);
                if(res.data){
                    const popupData = {
                        transactionRefNumber: res?.data?.transactionRefNumber,
                        beneficiaryName: res?.data?.beneficiaryAccount.beneficiaryName,
                        amount: res?.data?.amount
                    };
                    setIsLoading(false)
                    handleOpen(popupData)
                }
            } catch (err) {
                console.log(err)
                handleOpen(params)
            }
        // postQueryResult('makeTransactions', params).then(res => {

        //     console.log(res);
        //     const popupData={
        //         transactionRefNumber: res?.transactionRefNumber,
        //         beneficiaryName: res?.beneficiaryAccount.beneficiaryName,
        //         amount: res.amount
        //         }
        //     handleOpen(popupData);
        // })
        //     .catch(err => {
        //         console.log(err)
        //         handleOpen(params)

        //     })
    }

    const handleBeneficiaryChange = (e) => {
        const value = e.target.value 
        setselectedBeneficiaryData(beneficiary[value])
        setselectedBeneficiary(value)

    }
    const handleTransactionAmount = (e) => {
        const value = e.target.value
        setTransactionData({ amount: value, total_amount: Number(debitAccounts[selectedAccount]?.transactionCharge) + Number(value) })
        console.log({ amount: value, total_amount: debitAccounts[selectedAccount]?.transactionCharge + value })

    }

    const handleChange = (e) => {
        const value = e.target.value
        setAccount(e.target.value)
        setselectedAccountData(debitAccounts[value])

    }
    const handleReset = () => {
        setAccount({});
        setselectedAccountData({});
        setselectedBeneficiaryData({});
        setTransactionData({});
        setBeneficiary({});
        setTransactionData({amount: ''});
    };

    useEffect(() => {
        const fetchAccounts = async () => {
            if (userData && !isAccountsFetched.current) {
                try {
                    console.log(isAccountsFetched.current)
                    const res = await getAccounts(userData.username);
                    isAccountsFetched.current = true;
                    const newobj = {}
                    res?.accounts?.forEach(d => {
                        newobj[d.accountNumber] = d
                    })
                    setAccountData(newobj);
                    const newobjbenificery = {}
                    res?.beneficiarys?.forEach(d => {
                        newobjbenificery[d.beneficiaryAccountNumber] = d
                    })
                    setBeneficiary(newobjbenificery);
                    setFormValues();
                } catch (error) {
                    console.error('Error fetching accounts:', error);
                }
            }
        }
        fetchAccounts();
    }, [])

    return (
        <Styledpaper sx={{ padding: '10px', minHeight: 'calc(100vh - 100px)'}}>
             {isLoading && <Loader isLoading />}
            {ispopup.isOpen && <Popup handleClose={handleClose} {...ispopup} />}
            <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>Internal Fund Transfer</Typography>
            <Divider />
            <Typography variant='subtitle2' sx={{padding: '10px 10px 10px 5px', fontWeight: 'bold'}}>Account Details</Typography>
            <Grid container direction="row" justify="left" spacing={2} sx={{ padding: '5px' }}>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField
                        InputProps={{ sx: { height: 40, fontSize: 16 } }}
                        InputLabelProps={{ shrink: true, sx: { fontSize: 16, fontWeight: 'bold' } }}
                        variant="outlined"
                        select
                        fullWidth
                        required
                        name="debit_account"
                        label="Select debit account"
                        onChange={handleChange}
                        value={selectedAccount || ''}
                    >
                        {Object.keys(debitAccounts)?.map((data) => (
                            <MenuItem value={data} sx={{ fontSize: 16 }}>{data}</MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <FormControl varient="outlined" fullWidth>
                        <TextField
                            variant="outlined"
                            InputProps={{ sx: { height: 40, fontSize: 16, backgroundColor: '#FAF9F8' } }}
                            InputLabelProps={{ shrink: true, sx: { fontSize: 16, fontWeight: 'bold' } }}
                            label="Account name"
                            name="account_name"

                            disabled
                            // onChange={handleInputs}
                            value={selectedAccountData?.accountName || ''}
                        />
                    </FormControl>
                </Grid>
                </Grid>
                <Typography variant='subtitle2' sx={{padding: '10px 10px 10px 5px', fontWeight: 'bold'}}>Beneficiary Account Details</Typography>
                <Grid container direction="row" justify="left" spacing={2} sx={{ padding: '5px' }}>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField
                        InputProps={{ sx: { height: 40, fontSize: 16 } }}
                        InputLabelProps={{ shrink: true, sx: { fontSize: 16, fontWeight: 'bold' } }}
                        variant="outlined"
                        select
                        fullWidth
                        required
                        name="beneficiary_account"
                        label="Select beneficiary account"
                        value={selectedBeneficiary || ''}
                        onChange={handleBeneficiaryChange}
                    >
                        {Object.keys(beneficiary)?.map((data) => (
                            <MenuItem value={data} sx={{ fontSize: 16 }}>{data}</MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        disabled
                        name="c_balance"
                        label="Current balance"
                        InputLabelProps={{ shrink: true, sx: { fontSize: 16, fontWeight: 'bold' } }}
                        InputProps={{
                            sx: { height: 40, fontSize: 16, backgroundColor: '#FAF9F8' },
                            endAdornment: <InputAdornment position="end">{selectedAccountData.currency}</InputAdornment>,
                        }}
                        value={selectedAccountData?.balance || ''}
                    //     onChange={handleInputs}
                    //   >
                    //     {gender.map((data) => (
                    //       <MenuItem value={data.value}>{data.name}</MenuItem>
                    //     ))}
                    >
                    </TextField>
                </Grid>
            </Grid>

            <Grid container direction="row" justify="left" spacing={2} sx={{ padding: '5px' }}>

                <Grid item xs={12} sm={6} md={6}>
                    <TextField
                        InputProps={{ sx: { height: 40, fontSize: 16, backgroundColor: '#FAF9F8' } }}
                        InputLabelProps={{ shrink: true, sx: { fontSize: 16, fontWeight: 'bold' } }}
                        variant="outlined"
                        fullWidth
                        disabled
                        name="beneficiary_name"
                        label="Beneficiary account name"
                        value={selectedBeneficiaryData.beneficiaryName || ''}
                    //     onChange={handleInputs}
                    //   >
                    //     {boolean.map((data) => (
                    //       <MenuItem value={data.value}>{data.name}</MenuItem>
                    //     ))}
                    >
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField
                        InputProps={{ sx: { height: 40, fontSize: 16, backgroundColor: '#FAF9F8' } }}
                        InputLabelProps={{ shrink: true, sx: { fontSize: 16, fontWeight: 'bold' } }}
                        variant="outlined"
                        fullWidth
                        disabled
                        name="payment_type"
                        label="Payment type"
                        value={selectedBeneficiaryData.paymentType || ''}
                    //     onChange={handleInputs}
                    //   >
                    //     {boolean.map((data) => (
                    //       <MenuItem value={data.value}>{data.name}</MenuItem>
                    //     ))}
                    >
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField
                        id="outlined-basic" variant="outlined"
                        InputProps={{ sx: { height: 40, fontSize: 16 } }}
                        InputLabelProps={{ shrink: true, sx: { fontSize: 16, fontWeight: 'bold' } }}
                        fullWidth
                        required
                        name="amount"
                        label="Enter amount"
                        value={transactionData.amount}
                        onChange={handleTransactionAmount}
                    //   >
                    //     {boolean.map((data) => (
                    //       <MenuItem value={data.value}>{data.name}</MenuItem>
                    //     ))}
                    >
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField
                        InputProps={{ sx: { height: 40, fontSize: 16 } }}
                        InputLabelProps={{ shrink: true, sx: { fontSize: 16, fontWeight: 'bold' } }}
                        variant="outlined"
                        select
                        fullWidth
                        required
                        name="priority"
                        label="Priority"
                        value={selectedPriority || "LOW"}
                        onChange={(e) => setSelectedPriority(e.target.value)}
                    >
                        {[{ key: 'LOW', value: 'Low' }, { key: 'HIGH', value: 'High' }]
                            .map((item, index) => (
                                <MenuItem key={index} sx={{ fontSize: 16 }}
                                    value={item.key}
                                >
                                    {item.value}
                                </MenuItem>
                            ))}
                    </TextField>
                </Grid>
            </Grid>
            <Typography variant='subtitle2' sx={{padding: '10px 10px 10px 5px', fontWeight: 'bold'}}>Purpose</Typography>
            <Grid container direction="row" justify="left" spacing={2} sx={{ padding: '5px' }}>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField
                        InputProps={{ sx: { height: 40, fontSize: 16 } }}
                        InputLabelProps={{ shrink: true, sx: { fontSize: 16, fontWeight: 'bold' } }}
                        variant="outlined"
                        select
                        fullWidth
                        required
                        name="dedit_account"
                        label="Transaction purpose"
                        value={purpose || ''}
                        onChange={(e) => setPurpose(e.target.value)}
                    >
                        {["Salary", "Others"]
                            .map((values, index) => (
                                <MenuItem key={index} sx={{ fontSize: 16 }}
                                    value={values}
                                >
                                    {values}
                                </MenuItem>
                            ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField
                        InputProps={{ sx: { height: 40, fontSize: 16, backgroundColor: '#FAF9F8' } }}
                        InputLabelProps={{ shrink: true, sx: { fontSize: 16, fontWeight: 'bold' } }}
                        variant="outlined"
                        label="Transaction charges"
                        name="account_name"
                        fullWidth
                        disabled
                        // onChange={handleInputs}
                        value={selectedAccountData.transactionCharge | ''}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField
                        InputProps={{ sx: { height: 40, fontSize: 16 } }}
                        InputLabelProps={{ shrink: true, sx: { fontSize: 16, fontWeight: 'bold' } }}
                        variant="outlined"
                        disabled={purpose != "Others"}
                        fullWidth
                        name="c_balance"
                        label="Remarks"
                        value={transactionData.remarks || ''}
                        onChange={(e) => setTransactionData({
                            ...transactionData,
                            remarks: e.target.value
                        })}
                    //   >
                    //     {gender.map((data) => (
                    //       <MenuItem value={data.value}>{data.name}</MenuItem>
                    //     ))}
                    >
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField
                        InputProps={{ sx: { height: 40, fontSize: 16, backgroundColor: '#FAF9F8' } }}
                        InputLabelProps={{ shrink: true, sx: { fontSize: 16, fontWeight: 'bold' } }}
                        variant="outlined"
                        fullWidth
                        disabled
                        name="c_balance"
                        label="Total transaction amount"
                        value={transactionData.total_amount || ''}
                    //     onChange={handleInputs}
                    //   >
                    //     {gender.map((data) => (
                    //       <MenuItem value={data.value}>{data.name}</MenuItem>
                    //     ))}
                    >
                    </TextField>
                </Grid>
            </Grid>
            <br />
            <Grid container item xs={12} justifyContent="end">
                <Button variant='contained' onClick={handleReset} color='inherit' size='medium' sx={{ textTransform: 'none' }}>Clear</Button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant='contained' size="medium" sx={{
                    textTransform: 'none',
                    backgroundColor: '#204E32', m1: 1, "&.MuiButtonBase-root:hover": {
                        backgroundColor: '#204E32'
                    }
                }}
                    onClick={hadleMakeTransaction}>Submit</Button>

            </Grid>
        </Styledpaper>

    )
}

export default FundTransfer