import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Collapse from '@mui/material/Collapse';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import ListSubheader from '@mui/material/ListSubheader';

//images
import Logo from "../assets/images/Logo.png";
import Icon from "../assets/images/Icon.png";

//icons
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import TollOutlinedIcon from '@mui/icons-material/TollOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: '100%',
    minWidth: drawerWidth,
    position: 'relative',
    borderRadius: '4px',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,

    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)})`,
    position: 'relative',
    borderRadius: '4px',
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function MiniDrawer(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    return (

        <Box sx={{ display: 'fixed', height: '100%' }}>
            <CssBaseline />
            <Drawer variant="permanent" open={open} >
                {/* <DrawerHeader> */}


                {/* <ArrowCircleLeftIcon onClick={handleDrawerToggle} >
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <MenuIcon />}
                    </ArrowCircleLeftIcon> */}
                {/* </DrawerHeader> */}
                <IconButton onClick={handleDrawerToggle} sx={{ position: 'absolute', top: '45%', right: 0, zIndex: 2, border: '1px solid', width: 30, height: 30 }}>
                    { !open? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
                {/* <KeyboardArrowLeftOutlinedIcon onClick={handleDrawerToggle} sx={{marginLeft: '220px'}}/> */}
                {/* <Divider /> */}
                <NestedList handleTab={props.handleTab} selectedTab={props.selectedTab} />
            </Drawer>
        </Box>
    );
}
function NestedList(props) {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    console.log(props)
    return (
        <StyledList
            sx={{ width: '100%', maxWidth: 380, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        // subheader={
        //     <ListSubheader component="div" id="nested-list-subheader">
        //         Nested List Items
        //     </ListSubheader>
        // }
        >
            <div>
                <ListItemButton data-id={props.selectedTab == 0 && 'selectedTab'} onClick={(e) => props.handleTab(e, 0)}>
                    <ListItemIcon>
                        <HomeOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItemButton>
                <ListItemButton onClick={handleClick}>
                    <ListItemIcon>
                        <PaymentsOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Payments" />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton data-id={props.selectedTab == 1 && 'selectedTab'} onClick={(e) => props.handleTab(e, 1)} sx={{ pl: 2 }}>
                            <ListItemIcon>
                                <TollOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Internal Fund Transfer" />
                        </ListItemButton>
                    </List>
                </Collapse>
            </div>
            <div>
                <Divider />
                <ListItemButton>
                    <ListItemIcon>
                        <HelpOutlineOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Help" />
                </ListItemButton>
                <ListItemButton>
                    <ListItemIcon>
                        <SettingsOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Settings" />
                </ListItemButton>
            </div>
        </StyledList>
    );
}

const StyledList = styled(List)
    `&.MuiList-root{
position: relative;
height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
[data-id=selectedTab]{
        background-color: #204E32; 
        color: white;
 .MuiSvgIcon-root{
 color: white;
 }     
}
} .MuiTypography-root{
 font-size: 16px;
 }  
 .MuiListItemIcon-root{
 min-width: 50px;
 }

 `