import axios from "axios";
import KeycloakService from './keycloak';

// Create an instance of axios with custom configuration
const api = axios.create({
  baseURL: "https://api.vietcombank.virtusa.dev/transaction/api/v1",
  headers: {
    "Content-Type": "application/json",
    // Add any other headers you need
  },
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle errors globally here
    return Promise.reject(error);
  }
);

api.interceptors.request.use((config) => {
  if (KeycloakService.isLoggedIn()) {
    const cb = () => {
      config.headers.Authorization = `Bearer ${KeycloakService.getToken()}`;
      return Promise.resolve(config);
    };
    return KeycloakService.updateToken(cb);
  }
});

export default api;
