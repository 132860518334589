import React, { useEffect, useState, useContext } from 'react';
import styled from "styled-components";
import {
  TableRow, TableHead, TableCell, TableBody, Table, TableSortLabel, Paper, Grid, Typography, Button, Checkbox
} from "@mui/material";

import FilterListIcon from '@mui/icons-material/FilterList';

import { KeycloakContext } from '../service/KeycloakContext.js';
import commonServices from '../service';

const headers = { "transactionRefNumber": "Ref Number", "createdAt": "Date", "debitAccount": "Debit Account", "currency": "Currency", "amount": "Amount", "status": "Status" }

const headerData = [
  { label: 'Ref Number', sortKey: 'transactionRefNumber' },
  { label: 'Date', sortKey: 'createdAt', type: 'date' },
  { label: 'Debit Account', sortKey: 'debitAccount' },
  { label: 'Currency', sortKey: 'currency' },
  { label: 'Amount', sortKey: 'amount' },
  { label: 'Status', sortKey: 'status' },

];
const statusKeys = {
  "IN_PROGRESS": "In Progress",
  "SUCCESS": "Success",
  "FAILED": "Failed",
  "PENDING_APPROVAL": "Pending Approval"
}

export default function PastActivitySortTable({
  bodyData = [], handlePopup, colKeys = ['transactionRefNumber', 'createdAt', 'debitAccount', 'currency', 'amount', 'status'],
}) {
  const [order, setOrder] = useState("asc");
  const [tableData, setTableData] = useState([]);
  // table checked related function
  const [selectedRows, setChecked] = useState({});
  const [checkAll, setCheckAll] = useState(false);
  const { isInitialized, userInfo } = useContext(KeycloakContext)
  const { getQueryResult, postQueryResult } = commonServices()

  function cellFormat(row, key, rowidx) {
    let obj = {
      'createdAt': new Date(row[key]).toDateString(),
      'transactionRefNumber': ['APPROVED', 'FAILED', 'SUCCESS'].indexOf(row?.currentApprover?.status) > -1 ? <span>{row[key]}</span> : <a href='#' onClick={() => handlePopup(row, tableData, rowidx)}>{row[key]}</a>,
      'debitAccount': row[key]?.accountNumber,
      'status': statusKeys[row[key]]
    }
    console.log((row?.currentApprover?.status), ['APPROVED', 'FAILED', 'SUCCESS'].indexOf(row?.currentApprover?.status) > -1)
    return obj[key] ? obj[key] : row[key]
  }
  const getComparator = (property) => {
    let comparator;
    if (property[1] === "date") {
      comparator = (a, b) => dateComparator(a, b, property[0]);
    } else if (property[1] === "string") {
      comparator = order === "desc"
        ? (a, b) => descendingComparator(a, b, property[0])
        : (a, b) => -descendingComparator(a, b, property[0]);
    }
    return comparator;
  };


  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const dateComparator = (a, b, orderBy) => {
    const date1 = new Date(a[orderBy]);
    const date2 = new Date(b[orderBy]);
    return a && b && (order === "desc" ? date1 - date2 : date2 - date1);
  };

  const handleSort = (e) => {
    setOrder(order == "asc" ? "desc" : "asc");
    const comparator = getComparator([e.currentTarget.id, e.currentTarget.dataset.type]);
    const sortedData = bodyData && bodyData.sort(comparator);
    setTableData(sortedData);
  };
  // table checked related function
  const selectAll = async (e) => {
    const checkboxVal = e.target.checked;
    const checkedRow = selectedRows;
    tableData.map((row) => {
      if (!(row.accountStatus == "PENDING")) {
        checkedRow[row.id] = checkboxVal;
      }
    });
    setChecked(checkedRow);
    setCheckAll(checkboxVal);
    console.log(checkedRow);
  };
  const handlebulkUpdate = (params) => {
  console.log(userInfo)
  postQueryResult('updateTransactions', params,'PUT').then(res => {
      console.log(res)

  })
  }
  const bulkUpdate = async (action) => {
    // const action = e.currentTarget.getAttribute('data-id');
    const selectedids = Object.keys(selectedRows).filter((key) => selectedRows[key]);
    const selectedData = [];
    tableData.forEach((data) => {
      selectedids.indexOf(data.id) > -1 && selectedData.push(
        { status: action, approverId: userInfo.username, remarks:data.remarks, transactionRefNumber: data.transactionRefNumber }
      );
    });
    console.log(selectedData)
    handlebulkUpdate(selectedData);
    setChecked({});
  };

  const handleCheckBox = (e) => {
    const key = e.target.name;
    setChecked({
      ...selectedRows,
      [key]: e.target.checked,
    });
  };

  useEffect(() => {
    setOrder(order == "asc" ? "desc" : "asc");
    const comparator = getComparator(["Current Date", 'date']);
    const sortedData = bodyData && bodyData.sort(comparator);
    setTableData(sortedData);
  }, [bodyData]);

  return (
    <div>
      <Grid container justifyContent={'center'} alignItems={'center'} sx={{ fontWeight: 'bold', padding: '10px' }}>
        <Grid item xs={10}>
          <Typography variant='body1' >Recent Transactions</Typography>
        </Grid>
        <Grid item xs={1}>
          <Button variant='outlined' color='error' size='small'>Reject</Button>
        </Grid>
        <Grid item xs={1}>

          <Button variant='contained' color='success' size='small' onClick={() => bulkUpdate('APPROVED')}>Approve</Button>
        </Grid>
      </Grid>

      <TablePaper elevation={3} sx={{ position: 'sticky', top: '0px' }}>
        <Table aria-label="sticky table">
          <TableHead>
            <TableRow sx={{ position: 'sticky', top: '0px' }}>
              <HeaderCell align="center">
                <Checkbox
                  size='small'
                  checked={checkAll}
                  onChange={selectAll}
                />
              </HeaderCell>
              {bodyData.length > 0 && headerData && headerData.map((cellData) => (
                cellData?.sortKey
                  ? (
                    <HeaderCell
                      align="left"
                      onClick={handleSort}
                      id={cellData?.sortKey}
                      data-type={cellData?.type || "string"}
                    >
                      <TableSortLabel
                        active
                        direction={order}
                      >
                        {cellData.label}
                      </TableSortLabel>

                    </HeaderCell>
                  )
                  : <HeaderCell align="left">{cellData?.label}</HeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody disablePadding>
            {tableData.length > 0 && tableData.map((row, idx) => (

              <TableRow tabIndex={-1} key={row.id}>
                <StyledCell align="center">

                  <Checkbox
                    size='small'
                    checked={Boolean(selectedRows[row.id])}
                    onChange={handleCheckBox}
                    disabled={row.accountStatus === "PENDING"}
                    name={row.id}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </StyledCell>
                {colKeys.map((colKey) => (
                  <StyledCell align="left">
                    {cellFormat(row, colKey, idx)}
                  </StyledCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TablePaper>
    </div>
  );
}

const TablePaper = styled(Paper)`
&.MuiPaper-root{
overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  .MuiTableSortLabel-root {
    color: black;
  }
}
`;
const StyledCell = styled(TableCell)`
  &.MuiTableCell-root{
    padding: ${(props) => (props.padding ? props.padding : '0px')};
    border-right: 1px solid rgba(224, 224, 224, 1);
    font-size: 12px;
    .downloadBtn{
      cursor: pointer;
    }
    @media only screen and (max-width: 650px) {
      padding: 8px;
    }
  }
`;
export const HeaderCell = styled(StyledCell)`
&.MuiTableCell-root{
  font-weight: bold;
  font-size: 12px;
  background-color: white;
  color: #ffff;
}
`;
