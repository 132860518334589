import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

export default function CustomSeparator() {
  const breadcrumbs = [
    <Link underline="hover" key="1" color="Black" fontSize={'12px'} href="/home" >
      Home
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="Black"
      fontSize={'12px'} 
      href="#"
      onClick={handleClick}
    >
      Payments
    </Link>,
    <Typography key="3" color="Black" fontWeight={'bold'} fontSize={'12px'} >
      Initiate Transactions
    </Typography>,
  ];

  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" sx={{color: 'black'}} />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}