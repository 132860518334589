import { SearchOutlined } from '@mui/icons-material';
import { Paper, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { getTransactions } from '../service/services';
import DataTable from './DataTable';
import _ from 'lodash';
import api from '../service/api';
import SucessAlert from './ApprovePop';
import { Styledpaper } from './Home';
import Loader from './Loader';

const TableContainer = ({ userData, handlePop }) => {
    const [isMaker, setIsMaker] = useState(false);
    const [transaction, setTransactionData] = useState([]);
    const [totalElements, setTotalElements] = useState(0)
    const isTransactionFetched = useRef(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [approvedCount, setApprovedCount] = useState(0);
    const [isLoading, setIsLoading]= useState(false);
    const [isApproval, setIsApproval] = useState(true);

    const handleClosePop = () => {
        setModalOpen(false);
        fetchTransactions(5,0);
    }

    useEffect(() => {
        if(!isTransactionFetched.current){
            fetchTransactions(5,0);
        }
    }, [userData]);

    const fetchTransactions = async (pageSize,pageNumber, status) => {
        if (userData) {
            const isMaker = userData.roles.includes('MAKER') || false;
            setIsMaker(isMaker) ;
            let params = { 
                            pageSize,
                            pageNumber, 
                            sortBy: "createdAt",
                            sortDirection: "DESC",                                          
                        };
            if(status && status !== 'ALL'){
                params = {...params, filterBy: {status}}
            }
            try {
                const response = await getTransactions(params);
                isTransactionFetched.current = true;
                setTransactionData(response.content);
                setTotalElements(response.totalElements);
                setFilteredData(response.content);
            } catch (error) {
                console.error('Error fetching transactions:', error);
            }
        }
    };

    const bulkApprove = async (status) => {
        setIsLoading(true)
        const updatedRows = selectedRows.map(ref => ({
            status: status, 
          //  approverId: userData.username,
            transactionRefNumber: ref,
            remarks: ''
        }));
    
      try{
        const response = await  api.put('/transactions/bulk-approve',updatedRows);
        if(response){
            setIsApproval(status === "APPROVED")
            setIsLoading(false);
            setApprovedCount(updatedRows.length);
            setSelectedRows([]);
            setModalOpen(true);
        }

      }catch(err){
        console.log(err)
      }
    }

    const filterData = (term) => {
        if (term.length > 0) {
            const result = transaction.filter(item => {
                const refNumber = item.transactionRefNumber?.toString() || '';
                return refNumber.includes(term);
            });
            setFilteredData(result);
        } else {
            setFilteredData(transaction); 
        }
    };

    // Debounced filter function
    const debouncedFilterData = useMemo(
        () => _.debounce(filterData, 300),
        [transaction]
    );

    const handleSearch = (event) => {
        const searchText = event.target.value;
        setSearchTerm(searchText);
        debouncedFilterData(searchText);
    };



    const updateSelectedRef = (event, id) => {
        if (event.target.checked) {
          setSelectedRows([...selectedRows, id]);
        } else {
          setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
        }
      };

    return (
        <Styledpaper>
            {isLoading && <Loader isLoading />}
            <div className='p-2.5 flex items-center'>
                <div className='flex items-center w-1/2'>
                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>Recent Transactions</Typography>
                    <div className="relative ms-3 flex-1">
                        <input
                            type="text"
                            placeholder="Search reference number"
                            value={searchTerm || ''}
                            className="pl-5 pr-12 py-1 border border-gray-300 rounded-md shadow-sm w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                            onChange={handleSearch}
                        />
                        <SearchOutlined
                            className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-500"
                            size={14}
                        />
                    </div>
                </div>
                {!isMaker && (
                    <div className='flex-1 flex justify-end'>
                        <div className="flex space-x-4 justify-end">
                            <button
                                className='btn-l btn-reject me-2.5'
                                onClick={() => bulkApprove('REJECTED')}
                            >
                                Reject
                            </button>
                            <button
                                className="btn-l btn-approve"
                                onClick={() => bulkApprove('APPROVED')}
                            >
                                Approve
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <SucessAlert open={isModalOpen} 
                onClose={handleClosePop} 
                isApproval={isApproval}
                approvedCount={approvedCount}  />
            <DataTable totalElements={totalElements} handlePagination={fetchTransactions} 
            handlePopOpen={handlePop} selectedRows={selectedRows} transactionData={filteredData} handleRowSelection={updateSelectedRef} />
        </Styledpaper>
    );
}

export default TableContainer;
