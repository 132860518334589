import Keycloak from 'keycloak-js';

const initOptions = {
  url: 'https://sso.vietcombank.virtusa.dev/auth',
  realm: 'vietcombank-realm',
  clientId: 'vietcombank-fe',
};

const kc = new Keycloak(initOptions);

let isInitialized = false;

const initKeycloak = () => {
  if (!isInitialized) {
    return kc.init({
      onLoad: 'login-required',
      checkLoginIframe: true,
      pkcMethod: 'S256',
    }).then((authenticated) => {
      if (!authenticated) {
        window.location.reload();
      } else {
        isInitialized = true;
      }
    }).catch((error) => {
      console.error('Keycloak initialization failed:', error);
    });
  }
};


const doLogin = () => kc.login();

const doLogout = () => kc.logout();

const getToken = () => kc.token;

const getTokenParsed = () => kc.tokenParsed;

const isLoggedIn = () => !!kc.token;

const updateToken = (successCallback) => {
  return kc.updateToken(5)
    .then(successCallback)
    .catch(() => {
      doLogin();
    });
};

const getUserInfo = () => {
  if (kc.authenticated) {
    const tokenDetails = getTokenParsed();
    const userInfo = {
      username: tokenDetails.preferred_username,
      email: tokenDetails.email,
      name: tokenDetails.name,
      firstname: tokenDetails.family_name,
      lastname: tokenDetails.given_name,
      roles: tokenDetails.realm_access.roles
    }
    return userInfo;
  }

  return null;
};


const hasRole = (roles) => roles.some((role) => kc.hasRealmRole(role));

const KeycloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getTokenParsed,
  updateToken,
  getUserInfo,
  hasRole,
  kc
};

export default KeycloakService;
