import React, { useContext, useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Box, Checkbox, Menu, MenuItem, Typography } from '@mui/material';
import { ArrowDownward, ArrowUpward, ChevronRight, FilterList, PriorityHigh } from '@mui/icons-material';
import styled from 'styled-components';
import { getCurrentLevel, getRole } from '../service/services.js';
import { usersConstant } from '../service/const.js';
import { KeycloakContext } from '../service/KeycloakContext.js';

const DataTable = ({ transactionData, totalElements, handlePagination, handleRowSelection, selectedRows, handlePopOpen }) => {
  const [paginationModel, setPaginationModel] = useState({ pageSize: 5, page: 0 });
  const [isMaker, setIsMaker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // State for filter menu
  const open = Boolean(anchorEl);
  const { userInfo } = useContext(KeycloakContext)

  const handleFilterClick = (event) => {
    event.stopPropagation(); 
    setAnchorEl(event.currentTarget); 
  };

  const BoldHeader = styled.div`
    font-weight: bold;
  `;

  const filterList = [
     {key: 'ALL', value: 'All'},
    {key:'IN_PROGRESS', value:'In Progress'},
    {key:'SUCCESS', value:'Success'},
    {key:'FAILED', value:'Rejected'},
  ]

  const columns = [
    {
      field: 'transactionRefNumber',
      headerName: 'Ref Number',
      flex: 1,
      sortable: true,
      renderHeader: () => <BoldHeader>Ref Number</BoldHeader>,
      renderCell: (params) => {
        const userLevel = userInfo && getCurrentLevel(userInfo);
        const isCurrentUser = params.row.level == userLevel;
        const isDisable = params.row.status === 'pending_approval' || (isCurrentUser && params.row.status === 'failed')
        setIsMaker(userLevel === 0)
        return (<div className='flex items-center h-full'>
          {!isMaker && (
            <Checkbox
              checked={selectedRows.includes(params.row.transactionRefNumber)}
              disabled={!isDisable}
              onChange={(event) => handleRowSelection(event, params.row.transactionRefNumber)}
              style={{ paddingRight: '5px' }}
            />
          )}
          <Typography
            variant="body2"
            className='cursor-pointer'
            onClick={() => handlePopOpen(transactionData, params.row.index)}
            style={{ textDecoration: 'underline', color: '#204AE1' }}
          >
            {params.value}
          </Typography>
        </div>)
      },
    },
    { field: 'createdAt', headerName: 'Date', flex: 1, sortable: true,renderHeader: () => <BoldHeader>Date</BoldHeader>, },
    { field: 'debitAccount', headerName: 'Debit Account', flex: 1, sortable: true, renderHeader: () => <BoldHeader>Debit Account</BoldHeader>, },
    { field: 'amount', headerName: 'Amount', flex: 1, sortable: true ,  renderHeader: () => <BoldHeader>Amount</BoldHeader> ,
      renderCell: (params) => {
        const formattedAmount = new Intl.NumberFormat('vi-VN').format(params.value);
        return <span className='text-ellipsis overflow-hidden'>{formattedAmount}</span>;
      }
    },
    { field: 'currency', headerName: 'Currency', flex: 1, sortable: true , renderHeader: () => <BoldHeader>Currency</BoldHeader>},
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      sortable: true,
      renderHeader: (params) => (
        <div className='flex items-center'>
          <span className='me-2 font-bold'>Status</span>
          <FilterList
            size="small"
            aria-controls="status-filter-menu"
            aria-haspopup="true"
            onClick={handleFilterClick} // Use the custom filter click handler
          />
        </div>
      ),
      renderCell: (params) => {
        const getStatus = (status) => {
          switch (status) {
            case 'pending_approval':
              return { text: 'Pending Approval', color: '#431E69' };
            case 'in_progress':
              return { text: 'In Progress', color: '#E9933E' };
            case 'success':
              return { text: 'Success', color: '#4D715B' };
            case 'failed':
              return { text: 'Rejected', color: '#913D8F' };
            default:
              return { text: 'Unknown', color: 'grey' };
          }
        };

        const status = getStatus(params.value);

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              className='status-dot me-2'
              style={{ backgroundColor: status.color }}
            />
            <span className='text-ellipsis overflow-hidden'>{status.text}</span>
          </div>
        );
      },
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      width: 70,
      renderCell: (params) => (
        <Box className="flex items-center justify-between h-full">
          <Box className="flex flex-1 items-center">
            {params.row.priority === 'HIGH' && params.row.status === 'pending_approval' && (
              <PriorityHigh style={{ color: 'red', marginRight: 4 }} />
            )}
          </Box>
          <ChevronRight
            className="cursor-pointer"
            onClick={() => handlePopOpen(transactionData, params.row.index)}
          />
        </Box>
      ),
    },
  ];

  if (isMaker) {
    columns.splice(3, 0, {
      field: 'type',
      headerName: 'Transaction',
      flex: 1,
      sortable: true,
      renderHeader: () => <BoldHeader>Transaction</BoldHeader>,
      renderCell: (params) => (
        <div className='flex items-center'>
          {params.value === 'DR' ? (
            <ArrowDownward style={{ color: 'red' }} />
          ) : (
            <ArrowUpward style={{ color: 'green' }} />
          )}
          <span className='ms-2'>{params.value === 'DR' ? 'Debit' : 'Credit'}</span>
        </div>
      ),
    });
  }else{
    filterList.push({key:'PENDING_APPROVAL', value:'Pending Approval'},)
  }

  const handlePaginationModelChange = (model) => {
    setPaginationModel(model);
    handlePagination(model.pageSize, model.page);
  };

  const handleFilterClose = (status) => {
    handlePagination(paginationModel.pageSize, paginationModel.page, status);
    setAnchorEl(null);
  };

  const rows = transactionData.map((row, index) => ({
    index: index,
    id: row.id,
    transactionRefNumber: row.transactionRefNumber,
    debitAccount: row.debitAccount?.accountNumber || '',
    currency: row.currency || '',
    type: row.transactionType || 'DR',
    amount: row.amount || 0,
    status: row.status ? row.status.toLowerCase() : 'unknown',
    createdAt: new Date(row.createdAt).toLocaleDateString(),
    updatedAt: new Date(row.updatedAt).toLocaleDateString(),
    priority: row.priority || 'LOW',
    level: row.currentApprovalLevel
  }));

  return (
    <div style={{ width: '99%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        disableColumnMenu
        disableSelectionOnClick
        components={{
          Toolbar: GridToolbar,
        }}
        pagination
        rowHeight={40}
        columnHeaderHeight={40}
        paginationMode="server"
        pageSize={paginationModel.pageSize}
        pageSizeOptions={[5, 10, 20]}
        onPaginationModelChange={handlePaginationModelChange}
        rowCount={totalElements}
        paginationModel={paginationModel}
      />
      <Menu
        id="status-filter-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        {filterList.map((item) => (
          <MenuItem key={item.key} onClick={() => handleFilterClose(item.key)}>
            {item.value}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default DataTable;
