import api from "./api";
import { usersConstant } from "./const";

export const getTransactions = async (userData) => {
    try {
      const response = await api.post('/transactions', userData);
      return response.data;
    } catch (error) {
      throw error;
    }
};

export const getAccounts =  async (userNumber) => {
  try {
    const response = await api.get(`beneficiary/userNumber?userNumber=${userNumber}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getCurrentLevel = (userInfo) => {
  if(userInfo){
    const role = userInfo.roles?.find(r => r.includes("VCB_"));
    return usersConstant[role];
  }
}