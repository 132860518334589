import React from "react";
import styled from "styled-components";
import Grid from '@mui/material/Grid';

export default function Footer({
    isInverted = false,
    hideSocialShare = false,
    useCaseTitle,
}) {
    const year = new Date().getFullYear();

    return (
        <FooterContainer isInverted={isInverted}>
            <Grid container justify="flex-start" alignItems="center"
                xs={12}
                spacing={7}
                direction="row"
                 alignContent={"center"} justifyContent={"center"} sx={{ color: 'white', fontSize: '12px', position: 'sticky', bottom: '10px' }}>
                <Grid item xs={6}>
                    ©Powerd by Vietcom Commercial Bank 2024. All Rights Reserved.
                </Grid>
                <Grid item>
                    FAQs
                </Grid>
                <Grid item>
                    User Guide(Corporates)
                </Grid>
                <Grid item>Terms & Conditions</Grid>
                <Grid item>VCB-iBanking Demo</Grid>
            </Grid>
        </FooterContainer>
    );
}

const FooterContainer = styled.footer`
  padding: ${(props) => (props.isInverted ? "50px 30px" : "30px")};
  margin: 0px;
  background-color: ${(props) => props.isInverted && "#2941b7"};
  border-radius: ${(props) => props.isInverted && "0px"};
  color: ${(props) => (props.isInverted ? "#ffffff" : "#2941b7")};
  img {
    height: 50px;
  }
  p {
    color: ${(props) => (props.isInverted ? "#ffffff" : "#2941b7")};
    padding-left: 18px;
    font-size: 11px;
    font-weight: 200;
  }
  a{
    text-decoration: none;
    color: ${(props) => (props.isInverted ? "#ffffff" : "#2941b7")};
    &:hover{
      text-decoration: underline;
    }
  }
  hr {
    background-color: #fc7703;
    height: 3px;
    margin: 5px 20px;
  }
  @media only screen and (max-width: 960px) {
    padding: 10px;
    img {
      height: 30px;
    }
    hr {
      margin: 3px 8px;
    }
    p{
      padding-left: 10px;
    }
    

`;
