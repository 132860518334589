import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ReCAPTCHA from 'react-google-recaptcha';
import { useEffect, useRef } from 'react';
import commonServices from '../service';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ls from 'localstorage-slim';

//images
import Logo from "../assets/images/Logo.png";
import Backdrop from "../assets/images/Backdrop.png";
import En from "../assets/images/En.webp";

//icons
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import { roles, roleBasedRoutes } from '../utils';
import Footer from './Footer';

// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignInSide() {
  const [eng, setAge] = React.useState('');
  // const handleChange = () => {
  //   setAge(event.target.value);
  // };
  const [isDisabled, setBtnDisable] = useState(true)
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [captchaToken, setCaptcha] = useState('');
  const [userData, setUserData] = useState({ userNumber: '', password: '' })
  const [iserror, seterror] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      userNumber: data.get('userNumber'),
      password: data.get('password'),
    });
  };
  const { getQueryResult, postQueryResult } = commonServices()
  const handleOnChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value
    })
  }
  const handleLogin = () => {
    const params = {
      userNumber: userData.userNumber,
      password: userData.password,
      recaptchaToken: captchaToken
    }
    postQueryResult('login', params).then(res => {
      ls.set("user", res, { encrypt: true, ttl: 86400000 });
      console.log(roles, roleBasedRoutes[res?.role]);
      let initialRoute = roleBasedRoutes[res?.role]?.initialRoute;
      console.log("initialRoute--->", initialRoute);
      initialRoute && navigate(initialRoute);
    })
      .catch(res => { seterror(true); inputRef?.current?.reset() })
  }
  const setRecaptchaToken = (value) => {
    console.log("Captcha value:", value);
    setCaptcha(value)
  }
  useEffect(() => {
    if (userData.userNumber && userData.password && captchaToken) {
      setBtnDisable(false)
    }
    else {
      setBtnDisable(true)

    }
    iserror && seterror(false)
  }, [userData, captchaToken])
  // useEffect(() => {
  //   getQueryResult("login").then(res => console.log(res))
  // }, [])
  return (
    <ThemeProvider theme={defaultTheme}>
      {/* <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            Vietcombank
          </Typography>
        </Toolbar>
      </AppBar> */}
      <BGImage src={Backdrop} />
      <Grid container spacing={0} justifyContent={"center"} alignItems={"center"} alignContent={"center"}>
        <Grid
          item
          xs={false}
          sm={4}
          md={8.5}>
          <img src={Logo} />
        </Grid>
        <Grid item
          xs={0.2}><LocalPhoneOutlinedIcon /></Grid>
        <Grid
          item
          xs={1.8}>
          <Typography variant='h6' >1900 545413</Typography>
        </Grid>
        <Grid
          item
          xs={0.3}>
          <img src={En} width={'80%'}></img>
          {/* <div>
            <FormControl sx={{ m: 0, minWidth: 60, boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}>
              <InputLabel id="demo-simple-select-autowidth-label"><img src={En} width={'80%'}></img> ENG </InputLabel>
              {/* <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={eng}
               // onChange={handleChange}
                autoWidth
                label="ENG"
                sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
              > */}
          {/* <MenuItem value="">
                  <em>ENG</em>
                </MenuItem>
                <MenuItem value={20}><img src={En} width={'5%'}></img></MenuItem>
                <MenuItem value={21}><img src={En} width={'5%'}></img></MenuItem>
                <MenuItem value={22}><img src={En} width={'5%'}></img></MenuItem> */}
          {/* </Select> 
            </FormControl>
          </div> */}
        </Grid>
        <Grid item xs={0.2}>
          <Typography variant='subtitle1'>EN</Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"} alignItems={"center"} alignContent={"center"} component="main">
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{ color: 'white' }}>
          <Typography variant="h3" style={{ fontWeight: 'bold' }}>
            Welcome to </Typography>
          <Typography variant="h3" style={{ fontWeight: 'bold' }}>Corporate Online Banking
          </Typography>
          <Typography variant="h6" >Secure access to manage your business finances effeciently!</Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} rounded>
          <Box
            sx={{
              my: 2,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar> */}
            <Typography variant="h5" style={{ color: '#431E69', fontWeight: 'bold' }}>
              Login to your account
            </Typography>
            <Typography variant="subtitle2" style={{ marginBottom: '10px' }}>
              Enter your details to log into Vietcom corporate banking
            </Typography>
            {iserror &&
              <Typography color={'red'}>Invalid Corporate Id or Password</Typography>
            }
            <Box component="form" noValidate onSubmit={handleSubmit}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="userNumber"
                label="Corporate ID"
                sx={{
                  width: 350
                }}
                InputProps={{
                  sx: { height: 45 },
                  startAdornment: (
                    <InputAdornment position="start">
                      <BusinessCenterIcon />
                    </InputAdornment>
                  ),
                }}
                name="userNumber" onChange={handleOnChange}
                autoComplete="userNumber"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name='password' onChange={handleOnChange}
                label="Password"
                sx={{
                  width: 350
                }}
                InputProps={{
                  sx: { height: 45 },
                  startAdornment: (
                    <InputAdornment position="start">
                      <KeyOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Grid container justifyContent={'center'} alignItems={"center"}>
                <Grid item xs={8}>
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  />
                </Grid>
                <Grid item xs={4} >
                  <Link href="#" variant="body2" sx={{ textDecoration: 'none' }}>
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
              <ReCAPTCHA ref={inputRef} sitekey="6LcfTRAqAAAAAHC7UFUFXaire73G8QCJcehGEwab" onChange={setRecaptchaToken} />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 1, mb: 0, width: 350, backgroundColor: '#204E32', ml: 1, "&.MuiButtonBase-root:hover": {
                    backgroundColor: '#204E32'
                  }
                }}
                onClick={handleLogin}
                disabled={isDisabled}
              >
                LOGIN
              </Button>
              {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid> */}
              {/* <Copyright sx={{ mt: 5 }} /> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </ThemeProvider>
  );
}

const BGImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`