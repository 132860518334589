import React, { Fragment } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CancelOutlined } from '@mui/icons-material';

const SucessAlert = ({ open, onClose, approvedCount, isApproval }) => {
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="approval-dialog-title"
        aria-describedby="approval-dialog-description"
        PaperProps={{
          className: 'overflow-hidden',
        }}
      >
        <DialogContent className="relative p-6">
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 20,
              top: 10,
              width: 19,
              height: 19,
              backgroundColor: '#1E1102',
              '&:hover': {
                backgroundColor: '#1E1102',
              },
            }}
          >
            <CloseIcon sx={{ color: 'white', fontSize: 16 }} />
          </IconButton>
          <div className="flex flex-col items-center text-center mt-5">
            {isApproval ? <CheckCircleIcon className="mb-5" style={{ fontSize: 80, color: '#308E55' }} /> : <CancelOutlined className="mb-5" style={{ fontSize: 80, color: 'red' }} /> } 
            <DialogContentText id="approval-dialog-description">
              <strong className="text-lg">{approvedCount} Transactions {isApproval ? 'Approved': 'Rejected'}!</strong>
            </DialogContentText>
            <DialogContentText id="approval-dialog-description">
              The selected transactions were {isApproval ? 'approved successfully.' : 'rejected'}
            </DialogContentText>
          </div>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'center' }} className='mb-5'>
          <Button
            onClick={onClose}
            variant="outlined"
            className='close-btn'
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default SucessAlert;
